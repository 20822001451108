"use client";

import { ChecklistItem } from "@/app/(marketing)/checklist-item";
import { CustomersCount } from "@/app/(marketing)/get-customers-count";
import FiveStars from "@/components/five-stars";
import { usePathname } from "next/navigation";
import React from "react";

export default function Callout() {
  return null;

  // if (usePathname() !== "/signup") {
  //   return null;
  // }

  // return (
  //   <>
  //     <FiveStars />

  //     <h2 className="text-3xl font-bold tracking-tight">
  //       Join <CustomersCount />+ developers
  //     </h2>

  //     <ul className="w-fit list-outside space-y-2">
  //       <ChecklistItem>Catch server, client, and edge errors</ChecklistItem>
  //       <ChecklistItem>No production dependencies</ChecklistItem>
  //       <ChecklistItem>No code changes</ChecklistItem>
  //       <ChecklistItem>Fixed pricing</ChecklistItem>
  //     </ul>
  //   </>
  // );
}
